<template>
    <div class="container-home survey-home">
        <HeaderTab @getPoint="getPoint"></HeaderTab>
        <img class="survey-img" :src="banner" alt="">
        <section class="survey-bottom flex ac jb">
            <div class="flex ac jc">
                <a @click="getPoint(item.remark)" v-for="(item,index) in info" :class="['flex ac jc survey-tab',index == 0 ? 'active' : '']">{{item.name}}</a>
            </div>
            <div class="icon-svy"></div>
        </section>

        <div v-for="item in info">
            <a :id="item.remark" v-if="item.remark == 'synopsis'">
                <section class="content">
                    <img class="content-bg" src="../assets/suvery.png" />
                    <section class="title-bg  flex ac jc">
                        SYNOPSIS   
                        <section class="title-dec flex ac jc">{{item.name}}</section>
                    </section>
                    <section class="xians flex ac jc"></section>
                    <section class="content-dec flex ac jc" v-html="item.synopsis"></section>
                </section>

                <div class="suvery-all-msg  wow animate__animated animate__bounceInRight" v-html="content"></div>
            </a>
            <a :id="item.remark" v-if="item.remark == 'scenic'">
                <div class="content">
                    <section class="title-bg flex ac jc">
                        IMPORTANT
                        <section class="title-dec flex ac jc">{{item.name}}</section>
                    </section>
                    <section class="xians flex ac jc"></section>
                    <section class="content-dec flex ac jc" v-html="item.synopsis"></section>
                </div>

                <section class="more flex ac je" @click="goInto(item.remark)">全部</section>

                <section v-if="index < 3" :class="['svy-msg flex wow animate__animated animate__bounceInLeft',index == 2 ? 'mb0':'']" v-for="(item,index) in scenic">
                    <img :src="item.image" alt="">
                    <section class="svy-right">
                        <div class="title only">{{item.title}}</div>
                        <div class="svy-dec double" v-html="item.synopsis"></div>
                        <section class="time flex">开放时间   {{item.time}}</section>
                        <section class="time flex">联系电话   {{item.contact}}</section>
                        <section class="flex ac je">
                            <section class="svy-button flex ac jc" @click="goDetail(item,'scenic')">查看更多</section>
                        </section>
                    </section>
                </section>
            </a>
            <a :id="item.remark" v-if="item.remark == 'hotel'">
                <div class="content">
                    <section class="title-bg  flex ac jc">
                        HOTEL   
                        <section class="title-dec flex ac jc">{{item.name}}</section>
                    </section>
                    <section class="xians flex ac jc"></section>
                    <section class="content-dec flex ac jc" v-html="item.synopsis"></section>
                </div>

                <div class="swipers flex ac jc wow animate__animated animate__bounceInRight">
                    <section class="icon-left" @click="Swipers(1)" v-if="hotel.length > 3"></section>
                    <section class="banner-tabs" v-for="item in hotel">
                        <img :src="item.image" alt=""> 
                        <section class="svy-name only">{{item.title}}</section>
                        <section class="button-more flex ac jc" @click="goInto('hotel')">查看更多</section>
                    </section>
                    <section class="icon-right" @click="Swipers(2)" v-if="hotel.length > 3"></section>
                </div>
            </a>
            <a :id="item.remark" v-if="item.remark == 'affairs'">
                <div class="content">
                    <section class="title-bg  flex ac jc">
                        AFFAIRS   
                        <section class="title-dec flex ac jc">{{item.name}}</section>
                    </section>
                    <section class="xians flex ac jc"></section>
                    <section class="content-dec " v-html="item.synopsis"></section>
                </div>

                <div class="line flex ac jc">
                    <section class="tab wow animate__animated animate__bounceInLeft" @click="goInto('affairs',item)" v-if="index < 3"  v-for="(item,index) in affairs">
                        <img :src="item.image" alt="">
                        <section class="tab-bottom">
                            <section class="only">{{item.room_title}}</section>
                            <section class="svy-title only">{{item.title}}</section>
                        </section>
                    </section>
                </div>
            </a>
        </div>

        <section class="svy-footer">
            <img class="img" src="../assets/footer-xiong.png" alt="">
            <img class="imgs" src="../assets/img1.png" alt="">
            <section class="tabs-img" v-html="affairs_content"></section>
        </section>

        <footerTab></footerTab>
    </div>
</template>

<script>
var util = require("@/utils/util");
import HeaderTab from "@/components/Header-tab.vue";
import footerTab from "@/components/Footer-bottom.vue";
import { Swipe, SwipeItem, Toast, Indicator } from "mint-ui";
import Swiper from "swiper"
import Name from 'wowjs' 
export default {
    name: "home",
    components: {
        HeaderTab,
        footerTab
    },
    
    data() {
        return {
            info:[],
            content:'',
            scenic:[],
            hotel:[],
            affairs:[],
            show:10,
            banner:'',
            affairs_content:''
        }
    },
    watch: {
        affairs_content() {
            this.$nextTick(() => {   //页面渲染完，在执行
                setTimeout(() => {
                    let wow = new Name.WOW({
                        live:false
                    })
                    wow.init()
                }, 100);
            })
        }
    },
    mounted() {
        this.post();
    },
    methods: {
        post() {
            const ts = this;
            Indicator.open("加载中...");
            util.requests("post", {
                    url: "pc/homeMenuList",
                }).then((res) => {
                    Indicator.close();
                    if (res.code) {
                        ts.banner = res.data[1].banner_list[0].image
                        ts.info =  res.data[1].children;
                    }
            });
            util.requests("post", {
                    url: "pc/surveyData",
            }).then((res) => {
                Indicator.close();
                if (res.code) {
                    ts.content = res.data.synopsis.content
                    ts.scenic = res.data.scenic;
                    ts.hotel = res.data.hotel;
                    ts.affairs = res.data.affairs; 
                    ts.affairs_content = res.data.affairs_content.content
                }
            });
        },
        Swipers(e) {
            const ts = this;
            if(e == 2) {
                var list = [];
                ts.hotel.forEach((item,index) => {
                    if(index > 0) {
                        list.push(item)
                    }
                });
                list[ts.hotel.length - 1] = ts.hotel[0]
                ts.hotel = list
            } else {
                var list = [];
                list.push(ts.hotel[ts.hotel.length - 1])
                console.log(ts.hotel[ts.hotel.length -1])
                ts.hotel.forEach((item,index) => {
                    if(index < ts.hotel.length -1) {
                        list.push(item)
                    }
                });
                ts.hotel = list
            }
        },
        showIndex(e) {
            this.List[e].show = !this.List[e].show
        },
        goInto(e,f) {
            if (f) {
                this.$router.push({path:'survey/detail',query: {type: e,id:f.id}})
            } else {
                this.$router.push({path:'survey/detail',query: {type: e}})
            }
        },
        goDetail(e,f) {
            this.$router.push({path:'/detail',query: {type: f, id:e.id}})
        },
        getPoint(e) {
            let anchorElement = document.getElementById(e);
            if(anchorElement) {
                anchorElement.scrollIntoView({
                    behavior: "smooth",  // 平滑过渡
                    block:    "start"  // 上边框与视窗顶部平齐。默认值
                });
            }
        }
    }
}
</script>