import myStorage from '../utils/modules/storage'
const TokenKey = '_token'
export function getToken() {
  return myStorage.local.get(TokenKey)
}
export function setToken(token) {
  return myStorage.local.set(TokenKey,token,60*60*24)
}
export function removeToken() {
  return myStorage.local.del(TokenKey)
}

const LanguageKey = 'Language'
export function getLanguage() {
  return myStorage.local.get(LanguageKey)
}
export function setLanguage(language) {
  return myStorage.local.set(LanguageKey,language)
}
export function removeLanguage() {
  return myStorage.local.del(LanguageKey)
}


const AidKey = '_Aid' //应用id设置
export function setAid(aid){
  return myStorage.local.set(AidKey,aid,60*60*24)
}
export function getAid() {
  return myStorage.local.get(AidKey)
}
export function removeAid() {
  return myStorage.local.del(AidKey)
}

const fileHost = 'fileHost' //图片和音频Host
export function setFileHost(val){
  val = JSON.stringify(val);
  return myStorage.local.set(fileHost,val,60*60*24)
}
export function getFileHost(type='') {
  let val = myStorage.local.get(fileHost);
  let hostList = JSON.parse(val);
  return type ? hostList[type] : hostList;
}
export function removeFileHost() {
  return myStorage.local.del(fileHost)
}

