<template>
    <div class="container-home survey-home">
        <HeaderTab @getPoint="getPoint"></HeaderTab>
        <img class="survey-img" :src="banner" alt="">
        <section class="survey-bottom flex ac jb">
            <div class="flex ac jc">
                <a @click="getPoint(item.remark)"v-for="(item,index) in info" :class="['flex ac jc survey-tab',index == 0 ? 'active' : '']">{{item.name}}</a>
            </div>
            <div class="icon-svy"></div>
        </section>
        <div v-for="item in info">
            <a :id="item.remark" v-if="item.remark == 'business'">
                <section class="content">
                    <img class="content-bg" src="../assets/suvery.png" />
                    <section class="title-bg  flex ac jc">
                        BUSSINESS   
                        <section class="title-dec flex ac jc">{{item.name}}</section>
                    </section>
                    <section class="xians flex ac jc"></section>
                    <section class="content-dec flex ac jc" v-html="item.synopsis"></section>
                </section>
                <section class="bussiness-content wow animate__animated animate__bounceInRight">
                    <section class="flex ac jb bussiness-table">
                        <div class="flex ac jc">周一至周四</div>
                        <div class="flex ac jc">周五至周日</div>   
                    </section>
                    <section class="bussiness-bottom flex ac jc">
                        <section class="bussion-left">
                            <div class="flex ac jb">
                                <div> 游客开放进园时间： </div>
                                <div>{{bussiness.tourist_open_time}}</div>
                            </div>
                            <div class="flex ac jb">
                                <div> 停止购票时间 </div>
                                <div>{{bussiness.tourist_no_buy_time}}</div>
                            </div>
                            <div class="flex ac jb">
                                <div> 停止入园时间 </div>
                                <div>{{bussiness.tourist_no_enter_time}}</div>
                            </div>
                        </section>
                        <section class="bussion-left">
                            <div class="flex ac jb">
                                <div> 游客开放进园时间： </div>
                                <div>{{bussiness.vip_open_time}}</div>
                            </div>
                            <div class="flex ac jb">
                                <div> 停止购票时间 </div>
                                <div>{{bussiness.vip_no_buy_time}}</div>
                            </div>
                            <div class="flex ac jb">
                                <div> 停止入园时间 </div>
                                <div>{{bussiness.vip_no_enter_time}}</div>
                            </div>
                        </section>
                    </section>                   
                </section>
            </a>
            <a :id="item.remark" v-if="item.remark == 'map'">
                <section class="content">
                    <section class="title-bg  flex ac jc">
                        MAP   
                        <section class="title-dec flex ac jc">{{item.name}}</section>
                    </section>
                    <section class="xians flex ac jc"></section>
                    <section class="content-dec flex ac jc" v-html="item.synopsis"></section>
                </section>
                <img :src="map" class="bussimg flex ac jc wow animate__animated animate__bounceInLeft" alt="">
            </a>
            <a :id="item.remark" v-if="item.remark == 'traffic'">
                <section class="content">
                    <section class="title-bg  flex ac jc">
                        TRAFFIC   
                        <section class="title-dec flex ac jc">{{item.name}}</section>
                    </section>
                    <section class="xians flex ac jc"></section>
                    <section class="content-dec flex ac jc" v-html="item.synopsis"></section>
                </section>
                <section>
                    <div class="bus-content flex ac jc wow animate__animated animate__bounceInRight">
                        <img src="../assets/sever-right.png" class="bus-right" alt="">
                        <div>
                            <div class="bus-left" v-for="(item,index) in traffic">
                                <div class="bus-index" v-if="index == 0"><img class="one" src="../assets/zero.png" alt=""></div>
                                <div class="bus-index" v-if="index == 1"><img class="one" src="../assets/one.png" alt=""></div>
                                <div class="bus-index" v-if="index == 2"><img class="one" src="../assets/two.png" alt=""></div>
                                <div class="bus-name">{{item.title}}</div>
                                <div class="bus-content" v-html="item.content"></div>

                            </div>
                        </div>
                        <img src="../assets/bus.png" class="bus-img" alt="">
                    </div>
                </section>
            </a>
            <a :id="item.remark" v-if="item.remark == 'tourist'">
                <section class="content">
                    <section class="title-bg  flex ac jc">
                        TOURIST   
                        <section class="title-dec flex ac jc">{{item.name}}</section>
                    </section>
                    <section class="xians flex ac jc"></section>
                    <section class="content-dec flex ac jc" v-html="item.synopsis"></section>
                </section>
                <section class="bussiness-content tour-content">
                    <div class="tour-tilte wow animate__animated animate__bounceInLeft" v-for="(item,index) in tourist">
                        <div class="flex ac">
                            <div>
                                <div class="tour-index" v-if="index == 0"><img class="ones" src="../assets/zero.png" alt=""></div>
                                <div class="tour-index" v-if="index == 1"><img class="ones" src="../assets/one.png" alt=""></div>
                                <div class="tour-index" v-if="index == 2"><img class="ones" src="../assets/two.png" alt=""></div>
                                <div class="tour-name flex ac jc">{{item.name}}</div>
                            </div>
                        </div>
                        <div class="tour-right flex ac" v-for="itm in item.info">
                            <div class="flex ac jc round"></div>{{itm.item}}
                        </div>

                    </div>
                </section>
            </a>
            <a :id="item.remark" v-if="item.remark == 'common'">
                <section class="content">
                    <section class="title-bg  flex ac jc">
                        COMMON   
                        <section class="title-dec flex ac jc">{{item.name}}</section>
                    </section>
                    <section class="xians flex ac jc"></section>
                    <section class="content-dec flex ac jc" v-html="item.synopsis"></section>
                </section>
                <section class="suy-problem">
                    <section class="problem-tab wow animate__animated animate__bounceInRight" v-for="item in common" @click="item.show = !item.show">
                        <section class="h100">
                            <section :class="['problem-question flex ac',item.show ? 'noborder' : '']">
                                <div class="problem-icon"></div>
                                <div class="h90p"> {{item.question}}</div>
                                <section :class="['problem-right',item.show ? 'transactive' : '']"></section>
                            </section>
                            <section v-if="item.show" class="problem-question flex ac">
                                <div class="answer-icon"></div>
                                <div class="h90p answers"> {{item.answer}}</div>
                            </section>
                        </section>
                    </section>
                </section>
            </a>
        </div>
        <footerTab></footerTab>
    </div>
</template>

<script>
var util = require("@/utils/util");
import HeaderTab from "@/components/Header-tab.vue";
import footerTab from "@/components/Footer-bottom.vue";
import { Swipe, SwipeItem, Toast, Indicator } from "mint-ui";
import Swiper from "swiper"
import Name from 'wowjs' 
export default {
    name: "home",
    components: {
        HeaderTab,
        footerTab
    },
    
    data() {
        return {
            banner:"",
            info:[],
            bussiness:{},
            map:{},
            traffic:{},
            tourist:{},
            common:[],
            show:13
        }
    },
    watch: {
        common() {
            this.$nextTick(() => {   //页面渲染完，在执行
                setTimeout(() => {
                    let wow = new Name.WOW({
                        live:false
                    })
                    wow.init()
                }, 100);
            })
        }
    },
    mounted() {
        this.post()
    },
    methods: {
        post() {
            const ts = this;
            Indicator.open("加载中...");
            util.requests("post", {
                    url: "pc/homeMenuList",
                }).then((res) => {
                    Indicator.close();
                    if (res.code) {
                        ts.banner = res.data[4].banner_list[0].image
                        ts.info =  res.data[4].children;
                    }
            });
            util.requests("post", {
                url: "pc/serviceData",
            }).then((res) => {
                Indicator.close();
                if (res.code) {
                    ts.bussiness = res.data.business;
                    ts.map = res.data.map;
                    ts.traffic = res.data.traffic;
                    ts.tourist = res.data.tourist;
                    res.data.common.forEach(item => {
                        item.show = false
                    });
                    ts.common = res.data.common;
                }
            });
        },
        changeTab(e) {
            console.log(e)
            this.choice = e
        },
        showIndex(e) {
            this.List[e].show = !this.List[e].show
        },
        getPoint(e) {
            let anchorElement = document.getElementById(e);
            if(anchorElement) {
                anchorElement.scrollIntoView({
                    behavior: "smooth",  // 平滑过渡
                    block:    "start"  // 上边框与视窗顶部平齐。默认值
                });
            }
        }
    }
}
</script>