<template>
    <div class="footer-bottom flex ac jc">
        <img src="../assets/beijing.png" class="beijing" alt="">
        <img src="../assets/waiceng.png" class="waiceng" alt="">
        <div class="bottom-icon">
            <img src="../assets/bottom-icon.png" alt="">
            <a target="_blank" :href="info.miitbeian_url.value" class="footer-msg flex ac jc">{{info.miitbeian.value}}</a>
        </div>
        <div class="footer-content">
            <div class="flex ac jc">
                <router-link to="/" class="div-tab">首页</router-link>
                <router-link  to="/person" class="div-tab">关于我们</router-link>
                <!-- <div class="div-tab">版权申明</div>
                <div class="div-tab">隐私政策</div> -->
                <router-link to="/person" class="div-tab">反馈</router-link>
            </div>
            <div class="flex ac footer-dec">{{info.mobile.description}}：{{info.mobile.value}}</div>
            <div class="flex ac footer-dec">{{info.scenic_address.description}}：{{info.scenic_address.value}}</div>
        </div>
        <div class="footer-right flex ac jc">
            <div class="img-icon mr70">
                <img  :src="info.wx_qrcode.value" alt="" class="wx">
                <div class="wx-name flex ac jc">{{info.wx_qrcode.description}}</div>
            </div>
            <div class="img-icon">
                <img  :src="info.weibo_qrcode.value" alt="" class="wx">
                <div class="wx-name flex ac jc">{{info.weibo_qrcode.description}}</div>
            </div>
        </div>
    </div>
</template>
<script>
var util = require("@/utils/util");
export default {
    name: "Bottom",
    data() {
        return {
            info:""
        }
    },
    mounted() {
        this.post()
    },
    methods: {
        post() {
            const ts = this;
            if (this.$store.state.rlue) {
                this.info = this.$store.state.rlue
            } else {
                util.requests("post", {
                        url: "pc/getSettingList",
                    }).then((res) => {
                        if (res.code) {
                            this.$store.state.rlue = res.data
                            this.info = res.data
                        }   
                });
            }
        },
    },
}
</script>