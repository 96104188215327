import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'swiper/css/swiper.css';
import 'mint-ui/lib/style.css'
import Mint from 'mint-ui'
import wx from 'weixin-js-sdk'
import "./css/style.scss"
import 'animate.css'
// import  VConsole  from  'vconsole';
// let vConsole = new VConsole();
Vue.use(Mint);
Vue.config.productionTip = false;
Vue.prototype.setDataArr = function (obj) {
	Object.assign(this.$data,obj);
}

//路由跳转后，页面回到顶部
router.afterEach(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
