<template>
    <div class="container-home">
        <HeaderTab @getPoint="getPoint"></HeaderTab>
        <div class="banner">
            <div class="swiper-container">
                <div class="swiper-wrapper" >
                    <div class="swiper-slide" v-for="item in bannerImg" :key="item.id">
                        <img :src="item.image" alt @click="banner(item.ad_link,item.id)" />
                    </div>
                </div>
                <div class="swiper-pagination"></div>
                <!-- <section class="home-dec">
                    <div class="title flex ac">GUCHERBG BAY</div>
                    <div class="dec double">{{banner_info.title}}</div>
                    <div class="more-dec" v-html="banner_info.remark"> </div>
                </section> -->
            </div>

        </div>
        <div v-for="item in info" >
            <a :id="item.remark" v-if="item.remark == 'activity'">
                <div class="content animate__duration-3s">
                    <img class="content-bg" src="../assets/content-bg.png" />
                    <section class="title-bg flex ac jc">
                            ACTIVITY
                        <section class="title-dec flex ac jc">{{item.name}}</section>
                    </section>
                    <section class="xians flex ac jc"></section>
                    <section class="content-dec flex ac jc" v-html="item.synopsis"></section>
                </div>
                <section class="more flex ac je" @click="goto()">更多</section>
                <div class="swipers flex ac jc">
                    <section class="icon-left" @click="Swipers(1)" v-if="activity.length > 3"></section>
                    <section :class="['banner-tabs wow animate__animated', idx == 1 ? 'animate__flipInX animate__duration-4s' : '' , idx == 0 ? 'animate__bounceInLeft animate__duration-3s' : '', idx == 2 ? 'animate__bounceInRight animate__duration-3s' : '',]" v-if="idx < 3"  v-for="(itm,idx) in activity">
                        <img :src="itm.image" alt="">
                        <section class="bottom">
                            <div class="activy flex ac">精彩活动</div>
                            <div class="title only">{{itm.title}}</div>
                            <div class="time double" v-html="itm.synopsis"></div>
                            <!-- <div class="time only">活动地点：{{itm.address}}</div> -->
                            <div class="enter flex ac jc" @click="goDetail(itm,1)">进入活动</div>
                        </section>
                    </section>
                    <section class="icon-right" v-if="activity.length > 3" @click="Swipers(2)"></section>
                </div>
            </a>
            <a :id="item.remark" v-if="item.remark == 'route'">
                <div class="content animate__duration-3s">
                    <section class="title-bg flex ac jc">
                        ROUTE
                        <section class="title-dec flex ac jc">{{item.name}}</section>
                    </section>
                    <section class="xians flex ac jc"></section>
                    <section class="content-dec flex ac jc" v-html="item.synopsis"></section>
                </div>
                <section class="more flex ac je" @click="goDetails()">更多</section>

                <div class="line flex ac jc">
                    <section :class="['tab wow animate__animated animate__bounceInLeft', index == 0 ? 'animate__delay-0s':'', index == 1 ? 'animate__delay-1s':'',index == 2 ? 'animate__delay-2s':'',]" v-if="index < 3" v-for="(item,index) in route">
                        <img :src="item.image" alt="">
                        <section class="tab-bottom">
                            <section class="flex ac jc bold">{{index + 1}}</section>
                            <section class="flex ac jc bold dec">{{item.title}}</section>
                            <section class="only decs">{{item.subtitle}}</section>
                            <section class="see-more flex ac jc"  @click="goDetail(item,'2')">查看更多</section>
                        </section>
                    </section>
                </div>
            </a>
            <a :id="item.remark" class="pre" v-if="item.remark == 'travel'">
                <div class="content animate__duration-3s">
                    <section class="title-bg  flex ac jc">
                        TRAVEL
                        <section class="title-dec flex ac jc">旅游资讯</section>
                    </section>
                    <section class="xians flex ac jc"></section>
                    <img class="zixun-bg" src="../assets/zixun2.png" />
                    <img class="zixun-bg1" src="../assets/zixun1.png" />
                </div>

                <div class="news flex ac jc">
                    <div class="left  wow animate__animated animate__bounceInLeft animate__duration-3s">
                        <img :src="choice_zixun.image" class="left-img" alt="">
                        <div class="left-title">
                            <div class="title flex ac jb">
                                <div class="bold activety only">{{choice_zixun.title}}</div>
                                <div>{{choice_zixun.create_time}}</div>
                            </div>
                            <div class="contents double" v-html="choice_zixun.synopsis"></div>
                            <div class="button-right flex ac jc" @click="goDetail(choice_zixun,'3')">查看更多</div>
                        </div>
                    </div>
                    <div class="right wow animate__animated animate__bounceInRight animate__duration-3s">
                        <section class="xinwen">旅游资讯</section>
                        <section class="tbs" v-for="(item,index) in news" v-if="index < 4" @click="changeInfo(index)">
                            <div class="flex ac jb">
                                <div class="tbs-title flex ac">{{item.title}}</div>
                                <div class="tbs-time flex ac">{{item.create_time}}</div>
                            </div>
                            <div class="tbs-content" v-html="item.synopsis">
                            </div>
                            <div class="right-icon" @click="goDetail(item,'3')"></div>
                        </section>
                    </div>
                </div>
                <router-link to="/scenic?type=mediareports" class="get-more flex ac jc">更多</router-link>
            </a>
        </div>
        <footerTab></footerTab>
    </div>
</template>

<script>
var util = require("@/utils/util");
import HeaderTab from "@/components/Header-tab.vue";
import footerTab from "@/components/Footer-bottom.vue";
import { Swipe, SwipeItem, Toast, Indicator } from "mint-ui";
import Name from 'wowjs' 
import Swiper from "swiper"
export default {
    name: "home",
    components: {
        HeaderTab,
        footerTab
    },
    data() {
        return {
            bannerImg:[],
            show:9,
            info:[],
            activity:[],
            route:[],
            news:[],
            choice_zixun:{},
            banner_info:"",
        }
    },
    watch: {
        bannerImg() {
            const ts = this;
            ts.$nextTick(() => {
                var mySwiper = new Swiper ('.swiper-container', {
                    autoplay:true,//自动滑动
                    speed:1000,//自动滑动开始到结束的时间（单位ms）
                    loop:true,//开启循环
                    pagination: {
                    el: '.swiper-pagination',
                },
                 
                  on: {
                        slideChangeTransitionEnd: function(){ //获取当前轮播的下标
                            ts.banner_info = ts.bannerImg[this.realIndex]
                       },
                    }
                })
            });
        },
        news() {
            this.$nextTick(() => {   //页面渲染完，在执行
                setTimeout(() => {
                    let wow = new Name.WOW({
                        live:false
                    })
                    wow.init()
                }, 100);
            })
        }
    },
    mounted() {
        this.post();
    },
    methods: {
        post() {
            const ts = this;
            Indicator.open("加载中...");
            util.requests("post", {
                    url: "pc/homeMenuList",
                }).then((res) => {
                    Indicator.close();
                    if (res.code) {
                        ts.bannerImg = res.data[0].banner_list;
                        ts.banner_info = ts.bannerImg[0]
                        ts.info =  res.data[0].children;
                    }
            });
            util.requests("post", {
                    url: "pc/homeData",
                    data:{type:2}
                }).then((res) => {
                    Indicator.close();
                    if (res.code) {
                        ts.activity = res.data.activity;
                        ts.route = res.data.route;
                        ts.news = res.data.travel;
                        ts.choice_zixun = ts.news[0]
                    }
            });
        },
        changeInfo(e) {
            this.choice_zixun = this.news[e]
        },
        Swipers(e) {
            const ts = this;
            if(e == 2) {
                var list = [];
                ts.activity.forEach((item,index) => {
                    if(index > 0) {
                        list.push(item)
                    }
                });
                list[ts.activity.length - 1] = ts.activity[0]
                ts.activity = list
            } else {
                var list = [];
                list.push(ts.activity[ts.activity.length - 1])
                console.log(ts.activity[ts.activity.length -1])
                ts.activity.forEach((item,index) => {
                    if(index < ts.activity.length -1) {
                        list.push(item)
                    }
                });
                ts.activity = list
            }
        },
        goto() {
            this.$router.push({path:'/scenic',query: {type:'activity'}})
        },
        goDetail(e,f) {
            if (f == '1') {
                this.$router.push({path:'/detail',query: {type: 'activity', id:e.id}})
            } else if (f == '2') {
                this.$router.push({path:'/detail',query: {type: 'route', id:e.id}})
            } else if (f == '3') {
                this.$router.push({path:'/detail',query: {type: 'travel', id:e.id}})
            }
        },
        goDetails() {
            this.$router.push({path:'/line'})
        },
        getPoint(e) {
            let anchorElement = document.getElementById(e);
            if(anchorElement) {
                anchorElement.scrollIntoView({
                    behavior: "smooth",  // 平滑过渡
                    block:    "start"  // 上边框与视窗顶部平齐。默认值
                });
            }
        }
    }
}
</script>