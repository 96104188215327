<template>
    <div class="container-home">
        <HeaderTab></HeaderTab>
        <div class="banner">
            <div class="swiper-container">
                <div class="swiper-wrapper" >
                    <div class="swiper-slide" v-for="item in bannerImg" :key="item.id">
                        <img :src="item.image" alt @click="banner(item.ad_link,item.id)" />
                    </div>
                </div>
                <div class="swiper-pagination"></div>
                <section class="home-dec">
                    <div class="title flex ac">GUCHERBG BAY</div>
                    <div class="dec double">{{banner_info.remark}}</div>
                    <div class="more-dec" v-html="banner_info.title"> </div>
                </section>
            </div>
        </div>

     
        <div class="content">
            <section class="title-bg flex ac jc">
                ROUTE
                <section class="title-dec flex ac jc">{{info.name}}</section>
            </section>
            <section class="xians flex ac jc"></section>
            <section class="content-dec flex ac jc" v-html="info.synopsis"></section>
        </div>

        <div class="line flex ac jc">
            <div class="swipers flex ac jc routed-line">
                <section class="icon-left" @click="Swipers(1)" v-if="route.length > 3"></section>
                <section class="banner-tabs wow animate__animated animate__bounceInDown" v-if="idx < 3"  v-for="(itm,idx) in route">
                    <img :src="itm.image" alt="">
                    <div class="line-top flex ac jc"></div>
                    <div class="line-bottom">
                        <section class="line-title only">{{itm.title}}</section>
                        <section class="line-enter flex ac jc" @click="goDetail(itm)">进入路线</section>
                    </div>
                </section>
                <section class="icon-right" v-if="route.length > 3" @click="Swipers(2)"></section>
            </div>
        </div>
        <footerTab class="wow animate__animated animate__bounceInUp"></footerTab>
    </div>
</template>

<script>
var util = require("@/utils/util");
import HeaderTab from "@/components/Header-tab.vue";
import footerTab from "@/components/Footer-bottom.vue";
import { Swipe, SwipeItem, Toast, Indicator } from "mint-ui";
import Swiper from "swiper"
import Name from 'wowjs' 
export default {
    name: "home",
    components: {
        HeaderTab,
        footerTab
    },
    
    data() {
        return {
            bannerImg:[],
            show:9,
            info:{},
            route:[],
            banner_info:"",
        }
    },
    watch: {
        bannerImg() {
            const ts = this;
            ts.$nextTick(() => {
                var mySwiper = new Swiper ('.swiper-container', {
                    autoplay:true,//自动滑动
                    speed:1000,//自动滑动开始到结束的时间（单位ms）
                    loop:true,//开启循环
                    pagination: {
                    el: '.swiper-pagination',
                },
                 
                  on: {
                        slideChangeTransitionEnd: function(){ //获取当前轮播的下标
                            ts.banner_info = ts.bannerImg[this.realIndex]
                       },
                    }
                })
            });
        },
        route() {
            this.$nextTick(() => {   //页面渲染完，在执行
                setTimeout(() => {
                    let wow = new Name.WOW({
                        live:false
                    })
                    wow.init()
                }, 100);
            }) 
        }
    },
    mounted() {
        this.post();
    },
    methods: {
        post() {
            const ts = this;
            Indicator.open("加载中...");
            util.requests("post", {
                    url: "pc/homeMenuList",
                }).then((res) => {
                    Indicator.close();
                    if (res.code) {
                        ts.bannerImg = res.data[0].banner_list;
                        ts.banner_info = ts.bannerImg[0]
                        ts.info =  res.data[0].children[1];
                    }
            });
            util.requests("post", {
                    url: "pc/homeData",
                }).then((res) => {
                    Indicator.close();
                    if (res.code) {
                        ts.route = res.data.route;
                    }
            });
        },
        changeInfo(e) {
            this.choice_zixun = this.news[e]
        },
        Swipers(e) {
            const ts = this;
            if(e == 2) {
                var list = [];
                ts.route.forEach((item,index) => {
                    if(index > 0) {
                        list.push(item)
                    }
                });
                list[ts.route.length - 1] = ts.route[0]
                ts.route = list
            } else {
                var list = [];
                list.push(ts.route[ts.route.length - 1])
                console.log(ts.route[ts.route.length -1])
                ts.route.forEach((item,index) => {
                    if(index < ts.route.length -1) {
                        list.push(item)
                    }
                });
                ts.route = list
            }
        },

        goDetail(e) {
            this.$router.push({path:'/detail',query: {type: 'route', id:e.id}})
        },
    }
}
</script>