<template>
    <div class="container-home survey-home">
        <HeaderTab @getPoint="getPoint"></HeaderTab>
        <img class="survey-img" :src="banner" alt="">
        <section class="survey-bottom flex ac jb">
            <div class="flex ac jc">
                <a @click="getPoint(item.remark)" v-for="(item,index) in info" :class="['flex ac jc survey-tab',index == 0 ? 'active' : '']">{{item.name}}</a>
            </div>
            <div class="icon-svy"></div>
        </section>

        <div v-for="item in info">
            <a :id="item.remark" v-if="item.remark == 'ticket'">
                <div class="content">
                    <section class="title-bg  flex ac jc">
                        TICKET   
                        <section class="title-dec flex ac jc">{{item.name}}</section>
                    </section>
                    <section class="xians flex ac jc"></section>
                    <section class="content-dec flex ac jc" v-html="item.synopsis"></section>
                </div>

                <section class="more flex ac je" @click="goInto(item.remark)">更多</section>

                <section v-if="index < 3" :class="['svy-msg flex  wow animate__animated animate__bounceInLeft',index == 2 ? 'mb0':'']" v-for="(item,index) in ticket" @click="goDetail(item,'ticket')">
                    <img :src="item.image" alt="">
                    <section class="svy-right">
                        <div class="title only">{{item.title}}</div>
                        <div class="svy-dec double" v-html="item.synopsis"></div>
                        <!-- <section class="time flex">开放时间   {{item.open_time}}</section>
                        <section class="time flex">联系电话   {{item.contact}}</section> -->
                        <section class="flex ac je">
                            <section class="svy-button flex ac jc">查看详情</section>
                        </section>
                    </section>
                </section>
            </a>
            <a :id="item.remark" v-if="item.remark == 'housing'">
                <div class="content">
                    <section class="title-bg  flex ac jc">
                        HOUSING   
                        <section class="title-dec flex ac jc">{{item.name}}</section>
                    </section>
                    <section class="xians flex ac jc"></section>
                    <section class="content-dec flex ac jc" v-html="item.synopsis"></section>
                </div>

                <section class="more flex ac je" @click="goInto(item.remark)">更多</section>

                <div class="swipers flex ac jc  wow animate__animated animate__bounceInRight">
                    <section class="icon-left" @click="Swipers(1)" v-if="housing.length > 3"></section>
                    <section v-if="index < 3" class="banner-tabs" v-for="(item,index) in housing" @click="goDetail(item,'housing')">
                        <img :src="item.image" alt="">
                        <section class="svy-name only">{{item.title}}</section>
                        <section class="svy-dec" v-html="item.synopsis"></section>
                        <section class="button-more flex ac jc">查看更多</section>
                    </section>
                    <section class="icon-right" @click="Swipers(2)" v-if="housing.length > 3"></section>
                </div>
            </a>
            <a :id="item.remark" v-if="item.remark == 'restaurant'">
                <div class="content">
                    <section class="title-bg  flex ac jc">
                        RESTAURANT   
                        <section class="title-dec flex ac jc">{{item.name}}</section>
                    </section>
                    <section class="xians flex ac jc"></section>
                    <section class="content-dec flex ac jc" v-html="item.synopsis"></section>
                </div>
                <section class="more flex ac je" @click="goInto(item.remark)">更多</section>

                <section v-if="index < 3" :class="['svy-msg flex  wow animate__animated animate__bounceInLeft']" v-for="(item,index) in restaurant" @click="goDetail(item,'restaurant')">
                    <img :src="item.image" alt="">
                    <section class="svy-right">
                        <div class="title only">{{item.title}}</div>
                        <div class="svy-dec double" v-html="item.synopsis"></div>
                        <!-- <section class="time flex">开放时间   {{item.open_time}}</section>
                        <section class="time flex">联系电话   {{item.contact}}</section> -->
                        <section class="flex ac je">
                            <section class="svy-button flex ac jc">查看更多</section>
                        </section>
                    </section>
                </section>
            </a>
        </div>
        <footerTab></footerTab>
    </div>
</template>

<script>
var util = require("@/utils/util");
import HeaderTab from "@/components/Header-tab.vue";
import footerTab from "@/components/Footer-bottom.vue";
import { Swipe, SwipeItem, Toast, Indicator } from "mint-ui";
import Swiper from "swiper"
import Name from 'wowjs' 

export default {
    name: "home",
    components: {
        HeaderTab,
        footerTab
    },
    
    data() {
        return {
            banner:'',
            info:'',
            show:12,
            ticket:[],
            housing:[],
            restaurant:[],
        }
    },
    watch: {
        restaurant() {
            this.$nextTick(() => {   //页面渲染完，在执行
                setTimeout(() => {
                    let wow = new Name.WOW({
                        live:false
                    })
                    wow.init()
                }, 100);
            })
        }
    },
    mounted() {
        this.post()
    },
    methods: {
        post() {
            const ts = this;
            Indicator.open("加载中...");
            util.requests("post", {
                    url: "pc/homeMenuList",
                }).then((res) => {
                    Indicator.close();
                    if (res.code) {
                        ts.banner = res.data[3].banner_list[0].image
                        ts.info =  res.data[3].children;
                    }
            });
            util.requests("post", {
                url: "pc/bookData",
            }).then((res) => {
                Indicator.close();
                if (res.code) {
                    ts.ticket = res.data.ticket
                    ts.housing = res.data.housing;
                    ts.restaurant = res.data.restaurant;
                }
            });
        },

        Swipers(e) {
            const ts = this;
            if(e == 2) {
                var list = [];
                ts.housing.forEach((item,index) => {
                    if(index > 0) {
                        list.push(item)
                    }
                });
                list[ts.housing.length - 1] = ts.housing[0]
                ts.housing = list
            } else {
                var list = [];
                list.push(ts.housing[ts.housing.length - 1])
                console.log(ts.housing[ts.housing.length -1])
                ts.housing.forEach((item,index) => {
                    if(index < ts.housing.length -1) {
                        list.push(item)
                    }
                });
                ts.housing = list
            }
        },
        goDetail(e,f) {
            this.$router.push({path:'/detail',query: {type: f, id:e.id}})
        },
        getPoint(e) {
            let anchorElement = document.getElementById(e);
            if(anchorElement) {
                anchorElement.scrollIntoView({
                    behavior: "smooth",  // 平滑过渡
                    block:    "start"  // 上边框与视窗顶部平齐。默认值
                });
            }
        },
        goInto(e,f) {
            if (f) {
                this.$router.push({path:'survey/detail',query: {type: e,id:f.id}})
            } else {
                this.$router.push({path:'survey/detail',query: {type: e}})
            }
        },
    }
}
</script>