<template>
    <div class="container-home survey-home person-home">
        <HeaderTab @getPoint="getPoint"></HeaderTab>
        <div v-if="sumbmit_show">
            <img class="survey-img" :src="banner" alt="">
            <section class="survey-bottom flex ac jb">
                <div class="flex ac jc">
                    <a @click="getPoint(item.remark)"v-for="(item,index) in info" :class="['flex ac jc survey-tab',index == 0 ? 'active' : '']">{{item.name}}</a>
                </div>
                <div class="icon-svy"></div>
            </section>

            <div v-for="item in info">
                <a :id="item.remark" v-if="item.remark == 'culture'">
                    <section class="content">
                        <img class="content-bg" src="../assets/suvery.png" />
                        <section class="title-bg  flex ac jc">
                            CULTURE   
                            <section class="title-dec flex ac jc">{{item.name}}</section>
                        </section>
                        <section class="xians flex ac jc"></section>
                        <section class="content-dec flex ac jc" v-html="item.synopsis"></section>
                    </section>
                    <section class="bussiness-content" v-html="culture.content"></section>
                </a>
                <a :id="item.remark" v-if="item.remark == 'feedback'">
                    <section class="content">
                        <section class="title-bg  flex ac jc">
                            FEEDBACK   
                            <section class="title-dec flex ac jc">{{item.name}}</section>
                        </section>
                        <section class="xians flex ac jc"></section>
                        <section class="content-dec flex ac jc" v-html="item.synopsis"></section>
                    </section>
                    <select v-model="choice_type" class="textarea flex ac jc w50">
                        <option v-for="item in leixing">{{item.name}}</option>
                    </select>
                    <textarea class="textarea flex ac jc" v-model="content" placeholder="您遇到的问题"></textarea>
                    <div class="person-msg flex ac jc">
                        <input type="text" class="w372" v-model="contacts" placeholder="您的姓名" />
                        <input type="text" class="w372 mr0" v-model="phone" placeholder="您的手机号" />
                    </div>
                    <div class="person-msg flex ac jc">
                        <input type="text" class="w372" v-model="captcha" placeholder="请输入验证码" />
                        <img class="person-code flex ac jc" :src="code1" alt="">
                        <div class="huang flex ac jc" @click="changeCode(1)">换一张</div>
                    </div>
                    <section class="person-button flex ac jc" @click="submits()">提交</section>
                </a>
                <a :id="item.remark" v-if="item.remark == 'zinforzmation'">
                    <section class="content">
                        <section class="title-bg  flex ac jc">
                            ZINFORZMATION   
                            <section class="title-dec flex ac jc">{{item.name}}</section>
                        </section>
                        <section class="xians flex ac jc"></section>
                        <section class="content-dec flex ac jc" v-html="item.synopsis"></section>
                    </section>
                    <section class="zhaoshang">
                        <input type="text" class="w503 flex ac jc" v-model="zhaoshang_name" placeholder="请填入您的企业名称">
                        <input type="text" class="w503 flex ac jc" v-model="zhaoshang_contacts" placeholder="请填入您的企业联系人">
                        <input type="text" class="w503 flex ac jc" v-model="zhaoshang_phone" placeholder="请填入您的联系电话">
                        <input type="text" class="w503 flex ac jc" v-model="zhaoshang_industry" placeholder="请填入您的所属行业">
                        <div class="flex ac jc">
                            <input type="text" class="w503 w188 flex ac jc" v-model="zhaoshang_captcha" placeholder="请填入验证码">
                            <img class="person-code flex ac jc" :src="code2" alt="">
                            <div class="huang flex ac jc" @click="changeCode(2)">换一张</div>
                        </div>
                        <section class="person-button flex ac jc" @click="ZhaoshangSubmit()">提交</section>
                    </section>
                </a>
                <a :id="item.remark" v-if="item.remark == 'contactus'">
                    <section class="content">
                        <section class="title-bg  flex ac jc">
                            CONTACTUS   
                            <section class="title-dec flex ac jc">{{item.name}}</section>
                        </section>
                        <section class="xians flex ac jc"></section>
                        <section class="content-dec flex ac jc" v-html="item.synopsis"></section>
                    </section>
                    <section class="last-person">
                        <div class="flex ac" v-for="(item,index) in contactus">
                            <img class="imgs" src="../assets/img1.png" alt="">
                            <div class="left-person">
                                <div class="flex ac jc">{{item.name}}</div>
                                <div v-if="index == 0" class="tel-phone flex ac jc"></div>
                                <div v-if="index == 1" class="tel-address flex ac jc"></div>
                            </div>
                            <div class="person-name" v-html="item.info[0].content"></div>
                        </div>
                    </section>
                </a>
            </div>
        </div>
        <div v-else>
            <section class="content success" v-if="info">
                <img class="content-bg" src="../assets/suvery.png" />
                <div class="success-icon flex ac jc"></div>
                <div class="submit flex ac jc">提交成功！</div>
                <div class="dec flex ac jc">非常感谢您的反馈</div>
                <div class="dec flex ac jc">您也可以直接联系我们</div>
                <div class="dec phone flex ac jc">{{infos.feedback_landline.description}}: {{infos.feedback_landline.value}}      {{infos.feedback_phone.description}}: {{infos.feedback_phone.value}}</div>
                <div class="button flex ac jc" @click="sumbmit_show = true">我知道</div>
            </section>
        </div>

        <footerTab></footerTab>
    </div>
</template>

<script>
var util = require("@/utils/util");
import HeaderTab from "@/components/Header-tab.vue";
import footerTab from "@/components/Footer-bottom.vue";
import { Swipe, SwipeItem, Toast, Indicator } from "mint-ui";
import Swiper from "swiper"
import Name from 'wowjs' 
export default {
    name: "home",
    components: {
        HeaderTab,
        footerTab
    },
    
    data() {
        return {
            banner:"",
            info:[],
            culture:{},
            contactus:{},
            show:14,
            code1:'',
            code2:'',
            content:'',
            contacts:'',
            phone:'',
            captcha:'',
            zhaoshang_name:'',
            zhaoshang_contacts:'',
            zhaoshang_phone:'',
            zhaoshang_industry:'',
            zhaoshang_captcha:'',
            sumbmit_show:true,
            choice_type:'请选择投诉类型',
            leixing: [
                {
                    name: '请选择投诉类型',
                    value:'0'
                },{
                    name: '消费',
                    value:'1'
                },{
                    name: '服务',
                    value:'2'
                },{
                    name: '安全',
                    value:'3'
                },{
                    name: '交通',
                    value:'4'
                },{
                    name: '环境',
                    value:'5'
                },{
                    name: '设施',
                    value:'6'
                },{
                    name: '住宿',
                    value:'7'
                },{
                    name: '其他',
                    value:'8'
                },
            ],
            infos:''
        }
    },
    watch: {
        contactus() {
            setTimeout(() => {
                let wow = new Name.WOW({
                    live:false
                })
                wow.init()
            }, 100);
        }
    },
    mounted() {
        this.post();
        setTimeout(() => {
            this.infos = this.$store.state.rlue
        }, 1000);
        this.code1 = 'https://guchengwan.16u.cc/api/web/pc/code?t' + Date.parse(new Date()) + '&id=1';
        this.code2 = 'https://guchengwan.16u.cc/api/web/pc/code?t' + Date.parse(new Date()) + '&id=2';
    },
    methods: {
        post() {
            const ts = this;
            Indicator.open("加载中...");
            util.requests("post", {
                    url: "pc/homeMenuList",
                }).then((res) => {
                    Indicator.close();
                    if (res.code) {
                        ts.banner = res.data[5].banner_list[0].image
                        ts.info =  res.data[5].children;
                    }
            });
            util.requests("post", {
                url: "pc/aboutData",
            }).then((res) => {
                Indicator.close();
                if (res.code) {
                    ts.culture = res.data.culture;
                    ts.contactus = res.data.contactus;
                } else {

                }
            });
            // util.requests("post", {
            //     url: "pc/code",
            // }).then((res) => {
            //     Indicator.close();
            //     if (res.code) {
            //         console.log(res.data)
            //     }
            // });
        },
        ZhaoshangSubmit() {
            const ts = this;
            if (!ts.zhaoshang_name) {
                Toast('请输入企业名称')
                return;
            } else if (!ts.zhaoshang_contacts) {
                Toast('请输入联系人')
                return;
            } else if (!ts.zhaoshang_phone) {
                Toast('请输入手机号')
                return;
            } else if (!ts.zhaoshang_industry) {
                Toast('请输入所属行业')
                return;
            } else if (!ts.zhaoshang_captcha) {
                Toast('请输入验证码')
                return;
            }
            util.requests("post", {
                url: "pc/businessSubmit",
                data: {name:ts.zhaoshang_name,contacts:ts.zhaoshang_contacts,phone:ts.zhaoshang_phone,industry:ts.zhaoshang_industry,captcha:ts.zhaoshang_captcha}
            }).then((res) => {
                Indicator.close();
                if (res.code) {
                    ts.code2 = 'https://guchengwan.16u.cc/api/web/pc/code?t' + Date.parse(new Date()) + '&id=2';
                    ts.zhaoshang_name = '';
                    ts.zhaoshang_contacts = '';
                    ts.zhaoshang_phone = '';
                    ts.zhaoshang_industry = '';
                    ts.zhaoshang_captcha = '';
                    ts.sumbmit_show = false
                } else {
                    Toast(res.msg)
                }
            });
        },
        submits() {
            const ts = this;
            if (ts.choice_type == '请选择投诉类型') {
                Toast('请选择投诉的类型')
                return;
            } else if (!ts.content) {
                Toast('请输入反馈内容')
                return;
            } else if (!ts.contacts) {
                Toast('请输入姓名')
                return;
            } else if (!ts.phone) {
                Toast('请输入手机号')
                return;
            } else if (!ts.captcha) {
                Toast('请输入验证码')
                return;
            }
            if (ts.choice_type == '消费') {
                var type = 1;
            } else if (ts.choice_type == '服务') {
                var type = 2;
            } else if (ts.choice_type == '安全') {
                var type = 3;
            } else if (ts.choice_type == '交通') {
                var type = 4;
            } else if (ts.choice_type == '环境') {
                var type = 5;
            } else if (ts.choice_type == '设施') {
                var type = 6;
            } else if (ts.choice_type == '住宿') {
                var type = 7;
            } else if (ts.choice_type == '其他') {
                var type = 8;
            }
            util.requests("post", {
                url: "pc/feedbackSubmit",
                data: {content:ts.content,contacts:ts.contacts,phone:ts.phone,captcha:ts.captcha,type:type}
            }).then((res) => {
                Indicator.close();
                if (res.code) {
                    ts.code1 = 'https://guchengwan.16u.cc/api/web/pc/code?t' + Date.parse(new Date()) + '&id=1';
                    ts.content = '';
                    ts.contacts = '';
                    ts.phone = '';
                    ts.captcha = '';
                    ts.sumbmit_show = false
                    
                } else {
                    Toast(res.msg)
                }
            });
        },
        changeTab(e) {
            console.log(e)
            this.choice = e
        },
        changeCode(e) {
            if (e == 1) {
                this.code1 = 'https://guchengwan.16u.cc/api/web/pc/code?t' + Date.parse(new Date()) + '&id=1';
            } else {
                this.code2 = 'https://guchengwan.16u.cc/api/web/pc/code?t' + Date.parse(new Date()) + '&id=2';
            }
        },
        showIndex(e) {
            this.List[e].show = !this.List[e].show
        },
        getPoint(e) {
            let anchorElement = document.getElementById(e);
            if(anchorElement) {
                anchorElement.scrollIntoView({
                    behavior: "smooth",  // 平滑过渡
                    block:    "start"  // 上边框与视窗顶部平齐。默认值
                });
            }
        }
    }
}
</script>