import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Survey from '../views/Survey.vue'
import Order from '../views/Order.vue'
import Scenic from '../views/Scenic.vue'
import SurveyDetail from '../views/SurveyDetail.vue'
import Service from '../views/Service.vue'
import Person from '../views/Person.vue'
import Detail from '../views/Detail.vue'
import Line from '../views/Line.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/survey',
    name: 'Survey',
    component: Survey
  },
  {
    path: '/survey/detail',
    name: 'SurveyDetail',
    component: SurveyDetail
  },
  {
    path: '/order',
    name: 'Order',
    component: Order
  },
  {
    path: '/scenic',
    name: 'Scenic',
    component: Scenic
  },

  {
    path: '/service',
    name: 'Service',
    component: Service
  },
  {
    path: '/person',
    name: 'Person',
    component: Person
  },
  {
    path: '/detail',
    name: 'Detail',
    component: Detail
  },
  {
    path: '/line',
    name: 'Line',
    component: Line
  }
]

const router = new VueRouter({
  routes
})

export default router
