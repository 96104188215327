<template>
    <div class="container-home survey-home survey-detail">
        <HeaderTab></HeaderTab>
        <img class="survey-img" :src="banner" alt="">
        <section class="detail-tabs flex ac">
            <div class="detail-pr flex ac jc" @click="$router.go(-1) ">
                {{title}}
            </div>
        </section>
        <a :name="choice_type" v-if="choice_type == 'synopsis'">
            <section class="content contents">
                <img class="content-bg" src="../assets/suvery.png" />
            </section>

            <div class="suvery-all-msg" v-html="content"></div>
        </a>
        <a :name="choice_type" v-if="choice_type == 'scenic'">
            <section class="content contents">
                <img class="content-bg" src="../assets/suvery.png" />
            </section>

            <section :class="['svy-msg flex']" @click="goDetail(item,'scenic')" v-for="(item,index) in scenic">
                <img :src="item.image" alt="">
                <section class="svy-right">
                    <div class="title only">{{item.title}}</div>
                    <div class="svy-dec double" v-html="item.synopsis"></div>
                    <section class="time flex">开放时间 {{item.time || item.open_time}}</section>
                    <section class="time flex">联系电话 {{item.contact}}</section>
                    <section class="flex ac je">
                        <section class="svy-button flex ac jc">查看更多</section>
                    </section>
                </section>
            </section>
        </a>
        <a :name="choice_type" v-if="choice_type == 'hotel'" >
            <section class="content">
                <img class="content-bg" src="../assets/suvery.png" />
            </section>

            <div class="swipers long-swiper">
                <section class="banner-tabs detail-hotel" v-for="item in hotel" @click="goDetail(item,'hotel')">
                    <img :src="item.image" alt=""> 
                    <section class="svy-name only">{{item.title}}</section>
                </section>
            </div>
        </a>
        <a :name="choice_type" v-if="choice_type == 'affairs'">
            <div class="line long">
                <section class="tab"  v-for="(item,index) in affairs"  @click="goDetail(item,'affairs')">
                    <img :src="item.image" alt="">
                    <section class="flex ac jc affairs-title">{{item.title}}</section>
                </section>
            </div>
        </a>
                <a :name="choice_type" v-if="choice_type == 'synopsis'">
            <section class="content contents">
                <img class="content-bg" src="../assets/suvery.png" />
            </section>

            <div class="suvery-all-msg" v-html="content"></div>
        </a>
        <a :name="choice_type" v-if="choice_type == 'ticket'">
            <section class="content contents">
                <img class="content-bg" src="../assets/suvery.png" />
            </section>

            <section :class="['svy-msg flex',]" @click="goDetail(item,'ticket')" v-for="(item,index) in ticket">
                <img :src="item.image" alt="">
                <section class="svy-right">
                    <div class="title only">{{item.title}}</div>
                    <div class="svy-dec double" v-html="item.synopsis"></div>
                    <!-- <section class="time flex">开放时间  {{item.time || item.open_time}}</section>
                    <section class="time flex">联系电话  {{item.contact}}</section> -->
                    <section class="flex ac je">
                        <section class="svy-button flex ac jc">查看更多</section>
                    </section>
                </section>
            </section>
        </a>
        <a :name="choice_type" v-if="choice_type == 'restaurant'">
            <section class="content contents">
                <img class="content-bg" src="../assets/suvery.png" />
            </section>

            <section :class="['svy-msg flex']" @click="goDetail(item,'restaurant')" v-for="(item,index) in restaurant">
                <img :src="item.image" alt="">
                <section class="svy-right">
                    <div class="title only">{{item.title}}</div>
                    <div class="svy-dec double" v-html="item.synopsis"></div>
                    <!-- <section class="time flex">开放时间  {{item.time || item.open_time}}</section>
                    <section class="time flex">联系电话  {{item.contact}}</section> -->
                    <section class="flex ac je">
                        <section class="svy-button flex ac jc">查看更多</section>
                    </section>
                </section>
            </section>
        </a>
        <a :name="choice_type" v-if="choice_type == 'housing'" >
            <section class="content contents">
                <img class="content-bg" src="../assets/suvery.png" />
            </section>

            <section :class="['svy-msg flex']" @click="goDetail(item,'housing')" v-for="(item,index) in housing">
                <img :src="item.image" alt="">
                <section class="svy-right">
                    <div class="title only">{{item.title}}</div>
                    <div class="svy-dec double" v-html="item.synopsis"></div>
                    <section class="flex ac je">
                        <section class="svy-button flex ac jc">查看更多</section>
                    </section>
                </section>
            </section>
            <!-- <div class="swipers long-swiper">
                <section class="banner-tabs detail-hotel" v-for="item in housing" @click="goDetail(item,'housing')">
                    <img :src="item.image" alt=""> 
                    <section class="svy-name only">{{item.title}}</section>
                </section>
            </div> -->
        </a>
        <footerTab></footerTab>
    </div>
</template>

<script>
var util = require("@/utils/util");
import HeaderTab from "@/components/Header-tab.vue";
import footerTab from "@/components/Footer-bottom.vue";
import { Swipe, SwipeItem, Toast, Indicator } from "mint-ui";
import Swiper from "swiper"
export default {
    name: "home",
    components: {
        HeaderTab,
        footerTab
    },
    
    data() {
        return {
            info:[],
            content:'',
            scenic:[],
            hotel:[],
            affairs:[],
            show:10,
            banner:'',
            affairs_content:'',
            choice_type:'',
            title:'',
            ticket:[],
            restaurant:[],
            housing:[]
        }
    },
    watch: {

    },
    mounted() {
        if (this.$route.query.type == 'scenic') {
            this.title = '固城湾概况  >     重要景点'
        } else if (this.$route.query.type == 'hotel') {
            this.title = '固城湾概况  >     酒店住宿'
        } else if (this.$route.query.type == 'affairs') {
            this.title = '固城湾概况  >     会务活动'
        } else if (this.$route.query.type == 'ticket') {
            this.show = 12
            this.title = '在线预定  >     门票预订'
        } else if (this.$route.query.type == 'housing') {
            this.show = 12
            this.title = '在线预定  >     住房预订'
        } else if (this.$route.query.type == 'restaurant') {
            this.show = 12
            this.title = '在线预定  >     餐饮预订'
        }
        this.choice_type = this.$route.query.type
        this.post();
    },
    methods: {
        post() {
            const ts = this;
            Indicator.open("加载中...");
            util.requests("post", {
                    url: "pc/homeMenuList",
                }).then((res) => {
                    Indicator.close();
                    if (res.code) {
                        ts.banner = res.data[1].banner_list[0].image
                        ts.info =  res.data[1].children;
                    }
            });
            if (ts.$route.query.type == 'affairs') {
                const ts = this;
                Indicator.open("加载中...");
                util.requests("get", {
                    url: "pc/getInfo?module=" +ts.$route.query.type + '&id=' + ts.$route.query.id,
                }).then((res) => {
                    Indicator.close();
                    if (res.code) {
                        ts.affairs = res.data;
                    }
                });
            } else if(ts.$route.query.type == 'ticket' || ts.$route.query.type == 'housing' || ts.$route.query.type == 'restaurant') {
                util.requests("post", {
                    url: "pc/bookData",
                }).then((res) => {
                    Indicator.close();
                    if (res.code) {
                        ts.ticket = res.data.ticket
                        ts.housing = res.data.housing;
                        ts.restaurant = res.data.restaurant;
                    }
                });
            }
            else{
                util.requests("post", {
                        url: "pc/surveyData",
                }).then((res) => {
                    Indicator.close();
                    if (res.code) {
                        ts.content = res.data.synopsis.content
                        ts.scenic = res.data.scenic;
                        ts.hotel = res.data.hotel;
                        ts.affairs_content = res.data.affairs_content.content
                    }
                });
            }
        },
        Swipers(e) {
            const ts = this;
            if(e == 2) {
                var list = [];
                ts.hotel.forEach((item,index) => {
                    if(index > 0) {
                        list.push(item)
                    }
                });
                list[ts.hotel.length - 1] = ts.hotel[0]
                ts.hotel = list
            } else {
                var list = [];
                list.push(ts.hotel[ts.hotel.length - 1])
                console.log(ts.hotel[ts.hotel.length -1])
                ts.hotel.forEach((item,index) => {
                    if(index < ts.hotel.length -1) {
                        list.push(item)
                    }
                });
                ts.hotel = list
            }
        },
        showIndex(e) {
            this.List[e].show = !this.List[e].show
        },
        goDetail(e,f) {
            if (f == 'affairs') {
                this.$router.push({path:'/detail',query: {type: f, id:e.id,club_id: e.idx_club_id}})
            } else {
                this.$router.push({path:'/detail',query: {type: f, id:e.id}})
            }
        }
    }
}
</script>