<template>
    <section class="header-tab flex ac jb">
        <div class="left flex ac je">
            <img src="../assets/logo.png" alt="">
        </div>
        <div class="right flex ac">
            <section v-for="item in dataList" @click="getRoute(item.id)"  :class="['tabs flex ac jc', $parent.show == item.id ? 'active': '']">{{item.name}}
                <div :class="['bottom-radius flex ac jc',$parent.show == item.id ? 'active': '']"></div>
                <section class="header-pop">
                    <section :class="['flex ac jc pop-name',id==itm.id ? 'active':'']" @click.stop="getModel(itm,item.id)" v-for="itm in item.children">{{itm.name}}</section>
                </section>
            </section>
        </div>
    </section>
</template>
<script>
var util = require("@/utils/util");
import HeaderTab from "@/components/Header-tab.vue";
import footerTab from "@/components/Footer-bottom.vue";
import { Swipe, SwipeItem, Toast, Indicator } from "mint-ui";
import Swiper from "swiper"
export default {
    name: "home",
    components: {
        HeaderTab,
        footerTab
    },
    props: {
        getPoint: {
            type: Function,
            default: null
        }
    },
    data() {
        return {
            dataList:[],
            id:''
        }
    },
    mounted() {
        this.post();
    },
    methods: {
        post() {
            const ts = this;
            Indicator.open("加载中...");
            util.requests("post", {
                    url: "pc/homeMenuList",
                }).then((res) => {
                    Indicator.close();
                    if (res.code) {
                        ts.dataList = res.data
                    }
            });
        },
        getRoute(e) {
            if (e == 9) {
                this.$router.push({path:'/'})
            } else if (e == 10) {
                this.$router.push({path:'/survey'})
            } else if (e == 12) {
                this.$router.push({path:'/order'})
            } else if (e == 11) {
                this.$router.push({path:'/scenic'})
            } else if (e == 13) {
                this.$router.push({path:'/service'})
            } else if (e == 14) {
                this.$router.push({path:'/person'})
            }
        },
        getModel(e,f) {
            const ts = this;
            if (f == 9) {
                if (window.location.hash = '#/') {
                    this.$emit('getPoint', e.remark)
                    ts.id = e.id
                } else {
                    this.getRoute(f)
                }
            } else if (f == 10) {
                if (window.location.hash = '#/survey') {
                    this.$emit('getPoint', e.remark)
                    ts.id = e.id
                } else {
                    this.getRoute(f)
                }
            } else if (f == 11) {
                if (window.location.hash = '#/scenic') {
                    ts.id = e.id
                    this.$emit('getPoint', e.remark)
                } else {
                    this.getRoute(f)
                }
            } else if (f == 12) {
                if (window.location.hash = '#/order') {
                    this.$emit('getPoint', e.remark)
                    ts.id = e.id
                } else {
                    this.getRoute(f)
                }
            } else if (f == 13) {
                if (window.location.hash = '#/service') {
                    this.$emit('getPoint', e.remark)
                    ts.id = e.id
                } else {
                    this.getRoute(f)
                }
            } else if (f == 14) {
                if (window.location.hash = '#/person') {
                    this.$emit('getPoint', e.remark)
                    ts.id = e.id
                } else {
                    this.getRoute(f)
                }
            }
        }
    }
}
</script>